@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --bg_grey: #ececec;
  --white: #ffffff;
  --mylo_yellow: #ffac1b;
  --clickable: #017698;
  --text: #585a5c;
  --border: #26465333;
  --popup_border: #ececec;

  --compliant: #7cd992;
  --not_tested: #a8a8a8;
	--non_compliant: #FF7900;
	--safety_risk: #ff0000
}

body {
	height: 100vh;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--white);
}

body {
	margin: 0 auto;
}

* {
	padding: 0;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	transition: ease .25s !important
}

h1, h2, h3, h4, h5, h6, li, a, p, span, input, label, textarea, i {
	color: var(--text)
}

input {
	font-size: 17px;
	background: none;
}

textarea:focus, input:focus{
    outline: none;
}

body > div > a {
    text-decoration: none;
}

button {
	cursor: pointer;
	transition: .25s;
}

td {
	height: 40px
}

th {
	background: #ffb71b;
}

tr:hover {
  background-color: #ffb71b !important;
}

.td div,
.th div {
	font-family: Arial, sans-serif;
}



.preload_container {
	height: 100vh;
	width: 100%;
	position: relative;
}

.preload{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  width:60px;
  height:60px;
}
.preload hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.preload :first-child{background:#19A68C;animation-delay:-1.5s}
.preload :nth-child(2){background:#F63D3A;animation-delay:-1s}
.preload :nth-child(3){background:#FDA543;animation-delay:-0.5s}
.preload :last-child{background:#193B48}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}













.indViewMap .mapboxgl-map {
	height: 100% !important;;
	width: 100% !important;
	position: relative;
	min-height: 400px;
	margin-bottom: 2.5rem !important;
}

.hiddenCanvasElement {
	display: none;
}

.mapboxgl-map {
  height: 100% !important;
  width: 100% !important;
  position: relative;
}

.mapboxgl-popup {
	position: absolute;
	top: -10px !important;
}

.invoice_row i {
	color: rgb(117, 117, 117);
	font-size: 17px !important;
}

#invoice_table table tr:hover > td div:not(.invoice_overdue_header),
#invoice_table table tr:hover > td div div button i {
	color: rgb(117, 117, 117) !important;
}

#invoice_table table tr:hover > td .invoice_overdue_header {
	color: red !important
}

#invoice_table table tr:hover > td {
	cursor: default !important;
}

.invoice_row:hover > a{
	cursor: pointer !important;
}

#custom_map {
	margin-top: 2.5rem;
	margin-bottom: 1.25rem;
}

#custom_map .mapboxgl-control-container {
	display: none !important;
}

.mapboxgl-control-container {
  position: absolute;
  left: 20px;
  top: 20px;
}

.mapboxgl-ctrl-geocoder {
	box-shadow: none !important;
}

.mapboxgl-ctrl-geocoder--input:focus {
	outline: none !important;
}

.mapboxgl-ctrl-attrib-inner {
	display: none;
}

.mapboxgl-canvas-container {
  position: relative;
}

.mapboxgl-canvas {
	border-radius: 10px;
}
.mapboxgl-marker {
	transition: none !important
}

.marker_btn {
	cursor: pointer;
}

.marker_custom {
	font-size: 30px;
	position: relative;
}

.reactour__popover span {
	color: white !important;
	text-decoration: none !important;
}

.marker_old {
	color: red
}
.marker_pending {
	color: #a8a8a8
}
.marker_incomplete {
	color: #ffb71b
}
.marker_completed {
	color: #53a653
}
.marker_upcoming {
	color: #56bae5
}

.marker_p_normal {
	color: #ffb71b
}

.marker_p_high {
	color: red
}

.marker_clicked {
	position: absolute;
	top: 0;
}

.css-qbdosj-Input input {
  padding: 0 !important;
  text-indent: 0 !important
}

.css-1nmdiq5-menu {
	box-sizing: border-box;
	background-color: var(--white) !important;
	width: 99% !important;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999999999999999999 !important;
  position: absolute;
  color: var(--text);
  border: 1px solid var(--border)
}


.rbc-toolbar {
	display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  row-gap: 0.5rem !important;
}

.rbc-agenda-event-cell {
	border-bottom: 1px solid white;
}

.rbc-agenda-time-cell {
	border-left: 1px solid #ddd;
}

.rbc-agenda-table tr:hover {
	background-color: unset !important;
}

.rbc-agenda-time-cell {
	display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-header,
.rbc-agenda-date-cell,
.rbc-agenda-time-cell,
.rbc-agenda-table tr {
	background-color: inherit !important
}


.inspection_event_class .rbc-agenda-event-cell {
	background-color: #dab894;
}

.case_event_class .rbc-agenda-event-cell {
	background-color: #f48498;
}

.service_event_class .rbc-agenda-event-cell {
	background-color: #9381ff;
}

.custom_event_class .rbc-agenda-event-cell {
	background-color: #deaaff;
}

.table_data table {
  max-height: 100% !important;
  height: auto !important;
}

.css-30ccao-Table::-webkit-scrollbar,
.table_data table::-webkit-scrollbar,
.table_data_left table::-webkit-scrollbar,
.table_data_right table::-webkit-scrollbar,
.admin_table::-webkit-scrollbar,
.sm_container::-webkit-scrollbar,
.popup_with_scrollbar::-webkit-scrollbar,
.grid_data::-webkit-scrollbar { 
	appearance: none;
	width: 8px !important;
}

.css-30ccao-Table::-webkit-scrollbar-thumb,
.table_data table::-webkit-scrollbar-thumb,
.table_data_left table::-webkit-scrollbar-thumb,
.table_data_right table::-webkit-scrollbar-thumb,
.popup_with_scrollbar::-webkit-scrollbar-thumb,
.grid_data::-webkit-scrollbar-thumb {
	background-color: var(--white);
	border-radius: 15px !important;
	border: 1px solid var(--white) !important;
}

.admin_table::-webkit-scrollbar-thumb,
.sm_container::-webkit-scrollbar-thumb, 
.popup_with_scrollbar::-webkit-scrollbar-thumb {
	background-color: #ffac1b;
	border-radius: 15px !important;
	border: 1px solid #ffac1b !important;
}


.css-30ccao-Table:hover::-webkit-scrollbar-thumb,
.table_data table:hover::-webkit-scrollbar-thumb,
.table_data_left table:hover::-webkit-scrollbar-thumb,
.table_data_right table:hover::-webkit-scrollbar-thumb,
.popup_with_scrollbar:hover::-webkit-scrollbar-thumb,
.grid_data:hover::-webkit-scrollbar-thumb {
	background-color: #ffac1b;
}


@media only screen and (min-width: 320px) and (max-width: 576px) {
  .inspection_event_class {
    border-top: none !important;
    row-gap: 0.25rem !important
  }

  .rbc-agenda-date-cell {
  	margin-bottom: 1rem !important;
  }

  .rbc-agenda-event-cell div {
    padding: 15px 0;
  }

  .rbc-agenda-event-cell div p {
    font-size: 14px !important;
  }

  .rbc-agenda-table {
      display: flex;
  }

  .rbc-agenda-table tbody {
  	width: 100%;
  }

}

.MuiInputBase-input {
	border: none !important;
}

.MuiOutlinedInput-notchedOutline {
	border-width: 1px;
  border-color: #fce7eb !important
}

.MuiPickersPopper-root {
	z-index: 99999999999 !important;
}

.MuiIconButton-root {
	padding: 8px !important;
	margin-right: -8px !important;
}

.MuiButtonBase-root svg {
	width: 1rem !important;
	height: 1rem !important
}

.css-1fdsijx-ValueContainer {
  padding: 3.75px 8px !important
}

.pac-container {
	z-index: 99999999999999 !important;
	margin-left: 1.4px !important;
	margin-top: 2.5px !important;
	background-color: var(--white);
	border-top: none;
}

.pac-item-query {
	color: var(--text)
}

.pac-item {
	border-top: 1px solid var(--border);
}

.pac-item:hover {
	background-color: var(--mylo_yellow);
}

.pac-item:hover > span,
.pac-item:hover > span span {
	color: #ffffff !important
}

.pac-container::after {
	display: none !important;
}

.canvas_signature {
	width: calc(100% - 2px);
}

.tox-fullscreen {
	top: -130% !important;
  left: -75% !important;
  width: 250% !important;
  height: 90vh !important
}

.row-select-selected,
.row-select-single-selected {
	background-color: white !important;
	font-weight: normal !important;
}
.css-1dimb5e-singleValue {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--text) !important;
}


.reactour__popover p {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	color: #585a5c;
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  and (orientation: portrait)  {
    .rbc-agenda-table tr {
        width: 100%;
    }

    .rbc-agenda-event-cell {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
    }
}


.toolsContainer {
	height: 100%;
	width: 100%;

	display: flex;
	align-items: center;
	position: relative;
}

:not(.css-30ccao-Table, .results_container, .table_data table, .admin_table, .sm_container, .popup_with_scrollbar, .grid_data)::-webkit-scrollbar { 
  display: none
}

.grid_data {
	min-height: 300px;
}

.complianceContainerOverflow {
    padding: 0px 30px;
    height: 100%;
    overflow: scroll !important;
    scrollbar-width: auto !important;
    scrollbar-color: #ffb71b none !important;
}

.complianceContainerOverflow::-webkit-scrollbar,
.results_container::-webkit-scrollbar {
	display: flex !important;
    width: 6px !important;
}

.complianceContainerOverflow::-webkit-scrollbar-track,
.results_container::-webkit-scrollbar-track {
    border-radius: 8px !important;
    background-color: none !important;
}

.complianceContainerOverflow::-webkit-scrollbar-thumb,
.results_container::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    background-color: #ffb71b !important;
}
.canvas_signature {
	border: 1px solid var(--border);
	border-radius: 5px;
}










.serviceTD {
	display: flex;
	align-items: center;
	height: 40px;
}
.serviceIcon {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: var(--grey);

	display: flex;
	align-items: center;
	justify-content: center;
}


.serviceIcon i {
	color: var(--darkblue);
	font-size: 20px;
}

.serviceTD p {
	font-size: 14px;
	text-align: left;
}

.smallerTD p {
	font-size: 12px !important
}
.serviceTD p span {
	color: var(--grey);
	font-size: 12px;
	font-weight: 500;
}

.serviceTD div {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white)
}

.serviceTD i {
    color: white
}

.serv {
    border: none;
    background: none;
	height: 100%;
	border-radius: 100px;
	display: flex;

	cursor: pointer;
}

.serv p {
	font-size: 13px;
	font-weight: 500;
	padding-right: 20px !important;
}

.servsmaller p {
	font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
}


.servIcon {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	background-color: var(--grey);

	display: flex;
	align-items: center;
	justify-content: center;
}


.servIcon i {
	color: var(--darkblue);
	font-size: 15px;
}

.serv:hover,
.serv:focus {
	background-color: var(--hovergreen)
}

.serv:hover > .servIcon,
.serv:focus > .servIcon {
	background-color: white !important
}

.serv:hover > .servIcon i,
.serv:focus > .servIcon i {
	color: var(--hovergreen) !important
}

.serv:hover p,
.serv:focus p {
	color: white !important
}

.custom-tooltip {
	color: var(--text) !important;
	background: var(--white);
	border: 1px solid var(--border);
	font-size: 15px;
	font-weight: bold;
	padding: 10px;
	box-sizing: border-box;
}


.shine-animation {
	background-color: rgba(255, 255, 255, 0.3);
	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.3) 0%,
		rgba(255, 255, 255, 0.1) 40%,
		rgba(80, 90, 92, 0.1) 50%,
		rgba(255, 255, 255, 0.3) 60%,
		rgba(255, 255, 255, 0.3) 100%
	);
	background-size: 400% 100%;
	background-position: 100% 50%;
	animation: animation-shine 5s 0s infinite;
}

@keyframes animation-shine {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}


.mapboxgl-popup-content {
	width: 250px;
}

.mapboxgl-ctrl-top-right {
	right: unset !important;
}